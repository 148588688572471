// Tasks module
import axios from "axios";

const resource_uri = "https://tasnim.uz/chiqimNew/all";

const state = {
  chiqims: [],
};

const getters = {
  allChiqims: (state) => state.chiqims,
};

const actions = {
  async fetchChiqims({ commit }) {
    const response = await axios.get(resource_uri);
    commit("setChiqims", response.data);
  },
};

const mutations = {
  setChiqims: (state, chiqims) => (state.chiqims = chiqims),
  // newTask: (state, task) => state.tasks.unshift(task),
  // updTask: (state, updatedTask) => {
  //     const index = state.tasks.findIndex(t => t.id === updatedTask.id);
  //     if(index !== -1) {
  //         state.tasks.splice(index, 1, updatedTask);
  //     }
  // },
  // deleteTask: (state, task) => state.tasks = state.tasks.filter(t => task.id !== t.id),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
