import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Reg.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/regList",
    name: "regList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegList.vue"),
  },
  {
    path: "/groupAdd",
    name: "groupAdd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GroupAdd.vue"),
  },
  {
    path: "/groupList",
    name: "groupList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GroupList.vue"),
  },
  {
    path: "/kirim",
    name: "kirim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Kirim.vue"),
  },
  {
    path: "/chiqim",
    name: "chiqim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Chiqim.vue"),
  },
  {
    path: "/todo",
    name: "todo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TodoList.vue"),
  },
  {
    path: "/groupOne/:id",
    name: "group.show",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GroupOne.vue"),
    props: true,
  },
  {
    path: "/userOne/:id",
    name: "user.show",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserOne.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
