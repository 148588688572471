<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Application </v-list-item-title>
          <v-list-item-subtitle> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-toolbar-title>Tasnim</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-btn depressed class="mt-1"></v-btn> -->
      <!-- <v-btn depressed class="mt-1">Register list</v-btn>
      <v-btn depressed class="mt-1">Group</v-btn> -->
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Register", icon: "mdi-account-multiple-plus", url: "/" },
        {
          title: "Register list",
          icon: "mdi-list-box-outline",
          url: "/regList",
        },
        { title: "Group add", icon: "mdi-account-group", url: "/groupAdd" },
        { title: "Group list", icon: "mdi-list-box", url: "/groupList" },
        { title: "Kirim", icon: "mdi-cash-100", url: "/kirim" },
        { title: "Chiqim", icon: "mdi-cash-multiple", url: "/chiqim" },
        { title: "Vazifalar", icon: "mdi-format-list-checks", url: "/todo" },
      ],
      drawer: null,
    };
  },
  
};
</script>
