<template>
  <v-sheet>
    <v-container class="px-10">
      <h1 class="dark--text py-3 text-center">Ro'yhatdan o'tish</h1>
      <v-form class="mt-16" ref="form">
        <v-row class="justify-center">
          <!-- Ism -->
          <v-col cols="12" lg="4">
            <label>F.I.O</label>
            <v-text-field
              outlined
              v-model="data.name"
              :counter="40"
              label="F.I.O"
              required
              class="mt-6"
              :rules="Name"
            ></v-text-field>
          </v-col>
          <!-- Telefon raqam -->
          <v-col cols="12" lg="4">
            <label>Telefon raqam</label>
            <v-text-field
              outlined
              v-model="data.phone"
              :counter="9"
              label="Telefon raqam"
              required
              class="mt-6"
              type="number"
              :rules="Phone"
            ></v-text-field>
          </v-col>
          <!-- Kursni tanlang -->
          <v-col cols="12" lg="4">
            <label>Kursni tanlang</label>
            <v-select
              :items="courses"
              v-model="data.course"
              label="Kursni tanlang"
              :rules="Course"
              outlined
              required
              class="mt-6"
            ></v-select>
          </v-col>
          <!-- Hafta kunlarini tanlang -->
          <v-col cols="12" lg="4">
            <label>Hafta kunlarini tanlang</label>
            <v-select
              :rules="Weekdays"
              v-model="data.courseWeek"
              :items="weekdays"
              label="Hafta kunlarini tanlang"
              outlined
              required
              class="mt-6"
            ></v-select>
          </v-col>
          <!-- Vaqtini tanlang -->
          <v-col cols="12" lg="4">
            <label>Vaqtini tanlang</label>
            <v-select
              v-model="data.courseHours"
              :rules="Time"
              :items="hours"
              label="Vaqtini tanlang"
              outlined
              required
              class="mt-6"
            ></v-select>
          </v-col>
          <!-- Vaqtini tanlang -->
          <v-col cols="12" lg="4">
            <label>Holatni tanlang</label>
            <v-select
              v-model="data.select"
              :rules="Time"
              :items="select"
              label="Holatni tanlang"
              outlined
              required
              class="mt-6"
            ></v-select>
          </v-col>
          <!-- Izoh yozing -->
          <v-col cols="12" lg="6">
            <label>Izoh yozing</label>
            <v-textarea
              v-model="data.comment"
              class="mt-6"
              outlined
              required
              name="input-7-4"
              label="Izoh yozing"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="primary mb-10 mx-auto" @click="register">
              Yuklash
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div class="modalActive" :class="{ active: modal }">
        <h1>Yuklandi</h1>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "Reg",
  data() {
    return {
      modal: false,
      courses: [
        "Dasturlash Frontend",
        "Dasturlash Backend",
        "Kompyuter savodxonligi",
        "Grafik dizayn",
        "Rus tili",
        "Ingliz tili",
        "Autocad",
      ],
      weekdays: [
        "Toq kunlar",
        "Juft kunlar",
        "Dam olish kunlari",
        "Farqi yo'q",
      ],
      hours: [
        "08:00 dan 10:00 gacha",
        "10:00 dan 12:00 gacha",
        "14:00 dan 16:00 gacha",
        "16:00 dan 18:00 gacha",
        "18:00 dan 20:00 gacha",
        "Farqi yo'q",
      ],
      select: ["Yosh", "Naqd"],
      data: {
        name: "",
        phone: "",
        course: "",
        courseWeek: "",
        courseHours: "",
        seriya: "",
        select: "",
        comment: "",
      },
      Name: [(value) => !!value || "To'ldirilishi shart."],
      Phone: [(value) => !!value || "To'ldirilishi shart."],
      Course: [(value) => !!value || "To'ldirilishi shart."],
      Weekdays: [(value) => !!value || "To'ldirilishi shart."],
      Time: [(value) => !!value || "To'ldirilishi shart."],
      Comment: [(value) => !!value || "To'ldirilishi shart."],
    };
  },

  methods: {
    register() {
      if (this.$refs.form.validate()) {
        this.axios
          .post("https://tasnim.uz/regg/add", this.data)
          .then((res) => {
            console.log(res, "Chiqdi");
            this.modal = true;
            setInterval(window.location.reload(), (timeout = 4000));
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.data.seriya = Math.floor(Math.random() * 90000) + 1;
  },
};
</script>

<style scoped>
.modalActive {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  width: 300px;
  height: 100px;
  border-radius: 10px;
  background-color: antiquewhite;
  display: none;
  padding: 25px 90px;
}
.active {
  display: block;
}
h1 {
  font-family: sans-serif;
  opacity: 0.6;
}
label {
  opacity: 0.8;
}
</style>
