// Tasks module
import axios from 'axios';

const resource_uri = "https://tasnim.uz/kirimNew/all";

const state = {
    kirims: []
};

const getters = {
    allKirims: state => state.kirims
};

const actions = {
    async fetchKirims({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setKirims', response.data);
    }
};

const mutations = {
    setKirims: (state, kirims) => state.kirims = kirims,
    // newTask: (state, task) => state.tasks.unshift(task),
    // updTask: (state, updatedTask) => {
    //     const index = state.tasks.findIndex(t => t.id === updatedTask.id);
    //     if(index !== -1) {
    //         state.tasks.splice(index, 1, updatedTask);
    //     }        
    // },
    // deleteTask: (state, task) => state.tasks = state.tasks.filter(t => task.id !== t.id),
};

export default {
    state, getters, actions, mutations
}