// Tasks module
import axios from "axios";

const resource_uri = "http://localhost:3000/group/all";

const state = {
  groups: [],
};

const getters = {
  allGroups: (state) => state.groups,
};

const actions = {
  async fetchGroups({ commit }) {
    const response = await axios.get(resource_uri);
    commit("setGroups", response.data);
  },
};

const mutations = {
  setGroups: (state, groups) => (state.groups = groups),
  // newTask: (state, task) => state.tasks.unshift(task),
  // updTask: (state, updatedTask) => {
  //     const index = state.tasks.findIndex(t => t.id === updatedTask.id);
  //     if(index !== -1) {
  //         state.tasks.splice(index, 1, updatedTask);
  //     }
  // },
  // deleteTask: (state, task) => state.tasks = state.tasks.filter(t => task.id !== t.id),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
