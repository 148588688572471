import Vue from "vue";
import Vuex from "vuex";
import kirims from "./modules/kirim";
import chiqims from "./modules/chiqim";
import users from "./modules/register";
import groups from "./modules/group";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    kirims,
    chiqims,
    users,
    groups,
  },
});
